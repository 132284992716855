export const get = function (key) {
  const result = localStorage.getItem(key);
  try {
    return JSON.parse(result);
  } catch (e) {
    return result;
  }
};

export const set = function (key, value) {
  let result = value;
  if (typeof value === "object") {
    result = JSON.stringify(value);
  }
  localStorage.setItem(key, result);
};
