import Vue from "vue"
import VueRouter from "vue-router"
import Nav from "@/views/layout/Nav.vue"

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "home",
        redirect: "/",
        component: Nav,
        children: [
            {
                path: "",
                name: "index",
                component: () => import("@/views/bus/index.vue"),
                meta: { title: "附近站点" },
            },
            // {
            //     path: 'simpleMap',
            //     name: 'simpleMap',
            //     component: () => import("@/views/bus/simpleMap.vue"),
            //     meta: { title: "实时位置" },
            // },
            {
                path: 'sameLine',
                name: 'sameLine',
                component: () => import("@/views/bus/sameLine.vue"),
                meta: { title: "同站线路" },
            },
            {
                path: 'timetable',
                name: 'timetable',
                component: () => import("@/views/bus/timetable.vue"),
                meta: { title: "发车时刻" },
            },
            {
                path: 'txMap',
                name: 'txMap',
                component: () => import("@/views/bus/txMap.vue"),
                meta: { title: "实时位置" },
            }
        ]
    },
];

const router = new VueRouter({
    base: process.env.BASE_URL,  //加入base url
    routes
});

export default router;
